import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Congreso from "../views/congreso";
import Autores from "../views/autores";
import Programacion from "../views/programacion"
import Patrocinadores from "../views/patrocinadores"
import Registro from "../views/registro"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/congreso",
    component: Congreso,
    children: [
      {
        path: "objetivos",
        name: "Objetivos",
        component: () =>
        import("../views/congreso/Objetivos.vue"),
      },
      {
        path: "invitados",
        name: "Nuestros Invitados",
        component: () =>
        import("../views/congreso/Conferencistas.vue"),
      },
      {
        path: "comites",
        name: "Comités",
        component: () =>
        import("../views/congreso/Comites.vue"),
      },
      {
        path: "videos",
        name: "Videos",
        component: () =>
        import("../views/congreso/Talleres.vue"),
      },
      {
        path: "afiche",
        name: "Afiche",
        component: () =>
        import("../views/congreso/Afiche.vue"),
      }
    ]
  },
  {
    path: "/autores",
    component: Autores,
    children: [
      {
        path: "plantillas",
        name: "Plantillas",
        component: () =>
        import("../views/autores/Plantillas.vue"),
      },
      {
        path: "trabajos",
        name: "Trabajos aceptados",
        component: () =>
        import("../views/autores/Trabajos.vue"),
      },
      {
        path: "memorias",
        name: "Memorias",
        component: () =>
        import("../views/autores/Memorias.vue"),
      },
      {
        path: "infoPonentes",
        name: "Ponentes",
        component: () =>
        import("../views/autores/Ponentes.vue"),
      },
    ]
  },
  {
    path: "/patrocinadores",
    component: Patrocinadores,
    children: [
      {
        path: "patrocinar",
        name: "Patrocinar",
        component: () =>
        import("../views/patrocinadores/comoPatrocinar.vue"),
      },
      {
        path: "patrocinadores",
        name: "Patrocinadores",
        component: () =>
        import("../views/patrocinadores/patrocinadores.vue"),
      }
    ]
  },
  {
    path: "/programacion",
    component: Programacion,
    children: [
      {
        path: "general",
        name: "Programación General",
        component: () =>
        import("../views/programacion/General.vue"),
      },
      {
        path: "programacionD",
        name: "Programación Detallada",
        component: () =>
        import("../views/programacion/ProgramacionD.vue"),
      },
      {
        path: "cronograma",
        name: "Cronograma",
        component: () =>
        import("../views/programacion/Cronograma.vue"),
      },
    ]
  },
  {
    path: "/registro",
    component: Registro,
    children: [
      {
        path: "informacion",
        name: "Información General",
        component: () =>
        import("../views/registro/Informacion.vue"),
      },
      {
        path: "pre-registro",
        name: "Pre-Registro",
        component: () =>
        import("../views/registro/PreRegistro.vue"),
      }
    ]
  },
  {
    path: "/lugar",
    component: Registro,
    children: [
      {
        path: "sede",
        name: "Sede del Congreso",
        component: () =>
        import("../views/lugar/sede.vue"),
      },
      {
        path: "local",
        name: "Información Local",
        component: () =>
        import("../views/lugar/InformacionLocal.vue"),
      },
      {
        path: "turismo",
        name: "Turismo",
        component: () =>
        import("../views/lugar/Turismo.vue"),
      },
    ]
  },
  {
    path: "/escuela",
    component: Registro,
    children: [
      {
        path: "convocatoria",
        name: "Escuela",
        component: () =>
        import("../views/escuela/Convocatoria.vue"),
      },
      {
        path: "cursos",
        name: "Cursos",
        component: () =>
        import("../views/escuela/Cursos.vue"),
      },
      {
        path: "seleccionados",
        name: "Seleccionados",
        component: () =>
        import("../views/escuela/Seleccionados.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
